<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>웨딩 장소 관리</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            장소명
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL">
          <p class="bold">
            지역
            <span>*</span>
          </p>
          <!-- <input type="text" /> -->
          <el-select
            v-model="region"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="광주광역시" label="광주광역시" value="광주광역시">
            </el-option>
            <el-option key="광양시" label="광양시" value="광양시"> </el-option>
            <el-option key="나주시" label="나주시" value="나주시"> </el-option>
            <el-option key="목포시" label="목포시" value="목포시"> </el-option>
            <el-option key="순천시" label="순천시" value="순천시"> </el-option>
            <el-option key="여수시" label="여수시" value="여수시"> </el-option>
            <el-option key="강진군" label="강진군" value="강진군"> </el-option>
            <el-option key="보성군" label="보성군" value="보성군"> </el-option>
            <el-option key="신안군" label="신안군" value="신안군"> </el-option>
            <el-option key="장성군" label="장성군" value="장성군"> </el-option>
            <el-option key="담양군" label="담양군" value="담양군"> </el-option>
            <el-option key="곡성군" label="곡성군" value="곡성군"> </el-option>
            <el-option key="고흥군" label="고흥군" value="고흥군"> </el-option>
            <el-option key="화순군" label="화순군" value="화순군"> </el-option>
            <el-option key="장흥군" label="장흥군" value="장흥군"> </el-option>
            <el-option key="해남군" label="해남군" value="해남군"> </el-option>
            <el-option key="영암군" label="영암군" value="영암군"> </el-option>
            <el-option key="영광군" label="영광군" value="영광군"> </el-option>
            <el-option key="완도군" label="완도군" value="완도군"> </el-option>
            <el-option key="고창군" label="고창군" value="고창군"> </el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">상단라벨</p>
          <el-select
            v-model="topLabel"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option
              key="스탬프투어"
              label="스탬프투어"
              value="스탬프투어"
            ></el-option>
            <el-option
              key="단독제휴"
              label="단독제휴"
              value="단독제휴"
            ></el-option>
            <el-option
              key="플래너PICK"
              label="플래너PICK"
              value="플래너PICK"
            ></el-option>
            <el-option key="기획전" label="기획전" value="기획전"></el-option>
            <el-option
              key="타임세일"
              label="타임세일"
              value="타임세일"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">이벤트라벨</p>
          <el-select v-model="label" placeholder="선택" class="searchCondition">
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option key="EVENT" label="EVENT" value="EVENT"></el-option>
            <el-option key="HOT" label="HOT" value="HOT"></el-option>
            <el-option key="NEW" label="NEW" value="NEW"></el-option>
            <el-option key="BEST" label="BEST" value="BEST"></el-option>
            <el-option key="COUPON" label="COUPON" value="COUPON"></el-option>
            <el-option key="추천" label="추천" value="추천"></el-option>
            <el-option
              key="후기이벤트"
              label="후기이벤트"
              value="후기이벤트"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <el-select
            v-model="category"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="HALL" label="웨딩홀" value="HALL"></el-option>
            <el-option key="HOTEL" label="호텔" value="HOTEL"></el-option>
            <el-option
              key="HOUSE"
              label="스몰/야외&amp;하우스웨딩"
              value="HOUSE"
            ></el-option>
            <!-- <el-option key="OUTDOOR" label="야외 웨딩" value="OUTDOOR"></el-option> -->
            <el-option key="MEETING" label="상견례" value="MEETING"></el-option>
            <el-option
              key="BIRTHDAY"
              label="돌잔치"
              value="BIRTHDAY"
            ></el-option>
            <el-option
              key="TRADITION"
              label="전통 혼례"
              value="TRADITION"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            행사가능요일
            <span>*</span>
          </p>
          <el-select
            v-model="workTime"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="월요일" label="월요일" value="월요일"> </el-option>
            <el-option key="화요일" label="화요일" value="화요일"> </el-option>
            <el-option key="수요일" label="수요일" value="수요일"> </el-option>
            <el-option key="목요일" label="목요일" value="목요일"> </el-option>
            <el-option key="금요일" label="금요일" value="금요일"> </el-option>
            <el-option key="토요일" label="토요일" value="토요일"> </el-option>
            <el-option key="일요일" label="일요일" value="일요일"> </el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            홀타입
            <span>*</span>
          </p>
          <el-select
            v-model="holeType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="일반 홀" label="일반 홀" value="일반 홀">
            </el-option>
            <el-option key="컨벤션 홀" label="컨벤션 홀" value="컨벤션 홀">
            </el-option>
            <el-option key="채플 웨딩" label="채플 웨딩" value="채플 웨딩">
            </el-option>
            <el-option
              key="하우스 웨딩"
              label="하우스 웨딩"
              value="하우스 웨딩"
            >
            </el-option>
            <el-option key="스몰 웨딩" label="스몰 웨딩" value="스몰 웨딩">
            </el-option>
            <el-option key="야외 웨딩" label="야외 웨딩" value="야외 웨딩">
            </el-option>
          </el-select>
          <!-- <input type="text" v-model="holeType" /> -->
        </div>
        <div class="flexL">
          <p class="bold">
            식사
            <span>*</span>
          </p>
          <el-select
            v-model="foodType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="KOREAN" label="한식" value="KOREAN"></el-option>
            <el-option key="WESTERN" label="양식" value="WESTERN"></el-option>
            <el-option key="CHINESE" label="중식" value="CHINESE"></el-option>
            <el-option key="BUFFET" label="뷔페" value="BUFFET"></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">식대</p>
          <input type="text" v-model="fee" />
        </div>
        <div class="flexL">
          <p class="bold">
            예식종류
            <span>*</span>
          </p>
          <el-select
            v-model="ceremonyType"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="SEPARATE" label="분리" value="SEPARATE"></el-option>
            <el-option key="SINGLE" label="단독" value="SINGLE"></el-option>
            <el-option key="SAMETIME" label="동시" value="SAMETIME"></el-option>
            <el-option key="ETC" label="기타" value="ETC"></el-option>
          </el-select>
        </div>
        <div class="flexL capacity">
          <p class="bold">
            보증 인원
            <span>*</span>
          </p>
          <input type="number" v-model="capacityMin" /><span> ~ </span>
          <input type="number" v-model="capacityMax" />
        </div>
        <div class="flexL">
          <p class="bold">
            주소
            <span>*</span>
          </p>
          <input type="text" v-model="address" />
        </div>
        <div class="flexL">
          <p class="bold">주차</p>
          <input type="text" v-model="parking" />
        </div>

        <div class="flexL">
          <p class="bold">
            홀 정보
            <!-- <span>*</span> -->
          </p>
          <button class="register" @click="handlePlaceModal(null, null)">
            등록하기
          </button>
        </div>
        <div class="flexL">
          <p class="bold">
            대표사진
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" alt="업로드이미지" />
                <a
                  class="cancel"
                  @click="handleDeleteFile(thumbnail, 'thumbnail')"
                >
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  id="imgUpload"
                  @change="sendFile('thumbnail')"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice"
              >대표사진은 3:2 비율로 업로드 됩니다. ex) 500 * 333</em
            >
          </div>
        </div>
        <div class="flexL">
          <p class="bold">투어 쿠폰</p>
          <input
            type="text"
            v-model="coupons"
            placeholder="(쿠폰종류별로 ',' 로 구분해주세요. 예)1.상품권, 2.영화표)"
          />
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/placemanage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
    <div class="box one">
      <!-- <div class="tableList" v-if="weddingHoles.length > 0"> -->
      <div class="tableList">
        <h2 class="bold">홀 리스트</h2>
        <table v-if="weddingHoles.length > 0">
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 30%" />
            <col style="width: 20%" />
            <col style="width: 20%" />
            <col style="width: 15%" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>홀 이름</th>
            <th class="right">식대</th>
            <th class="right">대관료</th>
            <th class="center">삭제</th>
          </tr>
          <tr v-for="(data, i) in weddingHoles" :key="i">
            <td>{{ i + 1 }}</td>
            <td @click="handlePlaceModal(data, i)">
              <em>{{ data.holeName }} / {{ data.floor }}F</em>
            </td>
            <td class="right">
              {{ data.fee ? numberFormat(data.fee) + "원" : "-" }}
            </td>
            <td class="right">
              {{ data.rentalFee ? numberFormat(data.rentalFee) + "원" : "" }}
            </td>
            <td class="center">
              <button class="table delete" @click="holeDelete(i)">삭제</button>
            </td>
          </tr>
        </table>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <PlaceModal
          v-if="placeVisible"
          :select="select"
          @close="close"
          @updateData="updateData"
        />

        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import {
  createWeddingPlace,
  fetchWeddingPlaceDetail,
  upadateWeddingPlace,
  removeWeddingPlace,
} from "@/api/admin";
import PlaceModal from "@/components/pop/PlaceModal";
import { format } from "@/mixins/format";

export default {
  components: { PlaceModal },
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: false,
      placeVisible: false,
      file: "",
      placeId: "",

      title: "",
      category: "",
      workTime: [],
      holeType: [],
      foodType: [],
      fee: "",
      ceremonyType: [],
      capacityMin: "",
      capacityMax: "",
      capacity: "",
      region: "",
      address: "",
      parking: "",
      thumbnail: "",
      images: "",
      coupons: "",
      weddingHoles: [],
      select: null,
      index: null,
      label: "",
      topLabel: "",
    };
  },
  watch: {
    fee: function (newValue) {
      if (newValue != null) {
        const result = newValue
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.fee = result;
      }
    },
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.placeId = this.$route.query.id;
      this.getWeddingPlace();
    }
  },
  methods: {
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    handlePlaceModal(data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.modalVisible = true;
      this.placeVisible = true;
    },
    close() {
      this.index = null;
      this.select = null;
      this.modalVisible = false;
      this.placeVisible = false;
    },
    holeDelete(index) {
      this.$confirm("홀정보를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        this.weddingHoles.splice(index, 1);
      });
    },
    getWeddingPlace() {
      fetchWeddingPlaceDetail(this.placeId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.workTime = res.data.data.workTime;
          this.holeType = res.data.data.holeType;
          this.foodType = res.data.data.foodType;
          this.fee = res.data.data.fee;
          this.ceremonyType = res.data.data.ceremonyType;
          this.capacityMin = res.data.data.capacityMin;
          this.capacityMax = res.data.data.capacityMax;
          this.region = res.data.data.region;
          this.address = res.data.data.address;
          this.parking = res.data.data.parking;
          this.thumbnail = res.data.data.thumbnail;
          this.weddingHoles = res.data.data.weddingHoles;
          this.coupons = res.data.data.coupons;
          this.topLabel = res.data.data.topLabel;
          this.label = res.data.data.label;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    updateData(data) {
      if (this.index != null) {
        this.weddingHoles.splice(this.index, 1, data);
      } else {
        this.weddingHoles.push(data);
        this.submit();
      }
    },
    sendFile(type) {
      if (type == "thumbnail") {
        if (this.thumbnail) {
          this.$alert("썸네일 삭제 후 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
        this.file = this.$refs.thumbnail.files[0];
      } else if (type == "images") {
        this.file = this.$refs.images.files[0];
      }
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = res.data.url;
            } else if (type == "images") {
              this.images.push(res.data.url);
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.storeId) {
          deletePostFile(this.storeId, url, type).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              if (type == "thumbnail") {
                this.thumbnail = "";
              } else if (type == "images") {
                this.images.splice(this.images.indexOf(url), 1);
              }
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeWeddingPlace(this.placeId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "웨딩 장소 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/placemanage");
          }
        });
      });
    },
    submit() {
      if (this.title == "") {
        this.$alert("장소명을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.region == "") {
        this.$alert("지역을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.category == "") {
        this.$alert("카테고리를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.workTime == "") {
        this.$alert("행사가능요일을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.holeType == "") {
        this.$alert("홀타입을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.foodType == "") {
        this.$alert("식사를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (this.fee == "") {
        //   this.$alert("식대를 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
      } else if (this.ceremonyType == "") {
        this.$alert("예식종류를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.capacityMin == "" || this.capacityMax == "") {
        this.$alert("보증인원을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.capacityMin > this.capacityMax) {
        this.$alert("보증인원을 정확히 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.address == "") {
        this.$alert("주소를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (this.parking == "") {
        //   this.$alert("주차를 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
      } else if (this.thumbnail == "") {
        this.$alert("대표사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (this.weddingHoles.length == 0) {
        //   this.$alert("홀 정보를 등록해 주세요.", {
        //   confirmButtonText: "확인",
        //   center: true,
        // });
        // return;
      }

      let data = {
        placeId: this.placeId,
        title: this.title,
        category: this.category,
        workTime: this.workTime,
        holeType: this.holeType,
        foodType: this.foodType,
        fee: this.uncomma(this.fee),
        ceremonyType: this.ceremonyType,
        capacityMin: this.capacityMin,
        capacityMax: this.capacityMax,
        region: this.region,
        address: this.address,
        parking: this.parking,
        thumbnail: this.thumbnail,
        weddingHoles: this.weddingHoles,
        coupons: this.coupons,
        topLabel: this.topLabel,
        label: this.label,
      };

      if (this.editMode) {
        upadateWeddingPlace(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("장소 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              // this.$router.push("/admin/placemanage");
              this.getWeddingPlace();
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createWeddingPlace(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("장소 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/placemanage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
