<template>
  <div class="dimbox register">
    <div class="mHeader">
      <div class="flexL tit">
        <p class="bold">홀 정보 등록</p>
      </div>
      <button @click="close">
        <span class="material-icons"> close </span>
      </button>
    </div>
    <div class="mBody">
      <div class="flexL">
        <p class="bold">
          홀 이름
          <span>*</span>
        </p>
        <input type="text" v-model="holeName" />
      </div>
      <div class="flexL">
        <p class="bold">층수</p>
        <input type="text" v-model="floor" />
      </div>
      <div class="flexL">
        <p class="bold">홀타입 <span>*</span></p>
        <input type="text" v-model="holeType" />
      </div>
      <div class="flexL">
        <p class="bold">보증 인원 <span>*</span></p>
        <input type="number" v-model="capacity" />
      </div>
      <div class="flexL">
        <p class="bold">
          식사
          <span>*</span>
        </p>
        <el-select v-model="foodType" multiple placeholder="선택" class="searchCondition">
          <el-option key="한식" label="한식" value="한식"></el-option>
          <el-option key="양식" label="양식" value="양식"></el-option>
          <el-option key="중식" label="중식" value="중식"></el-option>
          <el-option key="뷔페" label="뷔페" value="뷔페"></el-option>
        </el-select>
      </div>
      <div class="flexL">
        <p class="bold">식대</p>
        <input type="text" v-model="fee" />
      </div>
      <div class="flexL">
        <p class="bold">대관료</p>
        <input type="text" v-model="rentalFee" />
      </div>
      <div class="flexL">
        <p class="bold">연출료</p>
        <label>
          <input type="radio" value="true" v-model="productionFee" />
          포함
        </label>
        <label>
          <input type="radio" value="false" v-model="productionFee" />
          미포함
        </label>
      </div>
      <div class="flexL">
        <p class="bold">생화</p>
        <label>
          <input type="radio" value="true" v-model="flower" />
          포함
        </label>
        <label>
          <input type="radio" value="false" v-model="flower" />
          미포함
        </label>
      </div>
      <div class="flexL">
        <p class="bold">음주료</p>
        <label>
          <input type="radio" value="true" v-model="drink" />
          포함
        </label>
        <label>
          <input type="radio" value="false" v-model="drink" />
          미포함
        </label>
      </div>
      <div class="flexL">
        <p class="bold">본식 원판/스냅</p>
        <label>
          <input type="radio" value="true" v-model="snap" />
          포함
        </label>
        <label>
          <input type="radio" value="false" v-model="snap" />
          미포함
        </label>
      </div>
      <div class="flexL">
        <p class="bold">본식 영상촬영</p>
        <label>
          <input type="radio" value="true" v-model="video" />
          포함
        </label>
        <label>
          <input type="radio" value="false" v-model="video" />
          미포함
        </label>
      </div>
      <div class="flexL">
        <p class="bold">
          홀 사진
          <span>*</span>
        </p>
        <div class="upload">
          <div class="flexB">
            <div class="filebox">
              <label for="images">찾아보기</label>
              <input type="file" ref="images" id="images" @change="sendFile('images')" accept="image/*" multiple />
            </div>
          </div>
          <div class="flex images" v-if="images.length > 0">
            <div v-for="(image, i) in images" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image, i)">
                <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <button @click="close" class="close">취소</button>
        <button class="basic" @click="updateData">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile, deleteFile } from "@/api/file";

export default {
  props: ["select"],

  data() {
    return {
      file: "",
      editMode: false,
      holeName: "",
      floor: "",
      holeType: "",
      capacity: "",
      foodType: [],
      fee: "",
      rentalFee: "",
      productionFee: null,
      flower: null,
      drink: null,
      snap: null,
      video: null,
      images: [],
    };
  },
  watch: {
    fee: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.fee = result;
    },
    rentalFee: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.rentalFee = result;
    },
  },
  mounted() {
    if (this.select != null) {
      this.editMode = true;
      this.holeName = this.select.holeName;
      this.floor = this.select.floor;
      this.holeType = this.select.holeType;
      this.capacity = this.select.capacity;
      this.foodType = this.select.foodType;
      this.fee = this.select.fee;
      this.rentalFee = this.select.rentalFee;
      this.productionFee = this.select.productionFee;
      this.flower = this.select.flower;
      this.drink = this.select.drink;
      this.snap = this.select.snap;
      this.video = this.select.video;
      this.images = this.select.images;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    updateData() {
      if (this.holeName == "") {
        this.$alert("홀 이름을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.holeType == "") {
        this.$alert("홀 타입을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.capacity == "") {
        this.$alert("보증인원을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.foodType == "") {
        this.$alert("식사를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
        // } else if (this.fee == "") {
        //   this.$alert("식대를 입력해 주세요.", {
        //     confirmButtonText: "확인",
        //     center: true,
        //   });
        //   return;
      } else if (this.images.length == 0) {
        this.$alert("홀 사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      let data = {
        holeName: this.holeName,
        floor: this.floor,
        holeType: this.holeType,
        capacity: this.capacity,
        foodType: this.foodType,
        fee: this.uncomma(this.fee),
        rentalFee: this.uncomma(this.rentalFee),
        productionFee: this.productionFee,
        flower: this.flower,
        drink: this.drink,
        snap: this.snap,
        video: this.video,
        images: this.images,
      };
      this.$emit("updateData", data);
      this.$emit("close");
    },
    sendFile() {
      if (this.file == undefined) {
        return;
      }
      this.file = this.$refs.images.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.images.push(res.data.url);
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            this.images.splice(this.images.indexOf(url), 1);
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
  },
};
</script>
